import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import Newsletter from "../components/Newsletter";
import Category from "../components/Category";
import Video from "../components/Video";
import About from "../components/About";
import Outlet from "../components/Outlet";
import Social from "../components/Social";
import Slider from "../components/Slider";
import Showcase from "../components/Showcase";


// Note that Swiper component is for mobile only while Product component is for desktop only, both replacing the other

const Home = () => {
    return (
        <div>
            <Navbar />
            <Video/>
            <Slider/>
            <Category />
            <About />
           <Showcase />
            <Outlet />
            <Social/>
            <Newsletter />
            <Footer />
        </div>
    );
};

export default Home;
